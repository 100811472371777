import React from 'react'
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import SEO from 'components/elements/SEO/SEO'

const FAQPompei = () => {
  const { t } = useTranslation()

  return (
    <>
      <SEO title={t('faqPompei.title')} />
      <div className="faq-container closer">
        <h1>
          <Trans>{t('faqPompei.title')}</Trans>
        </h1>
        <h4>
          <Trans>{t('faq.quest1')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqErcolano.ans1')}</Trans>
          <Trans>{t('faqPompei.ans1')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest2')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqErcolano.ans2')}</Trans>
          <u>
            <Link to="/cancel-policy">
              <Trans>{t('cancelPolicy.title')}</Trans>
            </Link>
          </u>
        </p>
        <h4>
          <Trans>{t('faq.quest3')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans3.p1')}</Trans>
        </p>
        <ul style={{ listStyleType: 'disc' }}>
          <li>Porta Marina (Via Villa dei Misteri);</li>
          <li>Porta Esedra (Piazza Porta Marina Inferiore);</li>
          <li>Porta Anfiteatro (Piazza Immacolata).</li>
        </ul>
        <p>
          <Trans>{t('faqErcolano.ans3.p2')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest4')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqErcolano.ans4')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest5')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans5')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest6')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans6.p1')}</Trans>
        </p>
        <ul style={{ listStyleType: 'disc' }}>
          <li>
            <Trans>{t('faqPompei.ans6.li1')}</Trans>
          </li>
          <li>
            <Trans>{t('faqPompei.ans6.li2')}</Trans>
          </li>
          <li>
            <Trans>{t('faqPompei.ans6.li3')}</Trans>
          </li>
        </ul>
        <h4>
          <Trans>{t('faq.quest7')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans7')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest8')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans8')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest9')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqErcolano.ans9.p1')}</Trans>&nbsp;
          <u>
            <a href="mailto:info@pompeiify.com">email</a>
          </u>
          <Trans>{t('faqErcolano.ans9.p2')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest10')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans10')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest11')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans11')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest12')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans12')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest13')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans13')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest14')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqErcolano.ans14')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest15')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans15')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest16')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqErcolano.ans16')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest17')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans17')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest18')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqPompei.ans18')}</Trans>
        </p>
        <h4>
          <Trans>{t('faq.quest19')}</Trans>
        </h4>
        <p>
          <Trans>{t('faqErcolano.ans19')}</Trans>
          <Trans>{t('faqPompei.ans19')}</Trans>&nbsp;
          <u>
            <a href={t('faqPompei.ans19-1')} target="_blank" rel="noreferrer">
              <Trans>{t('faqPompei.ans19-1')}</Trans>
            </a>
          </u>
        </p>
      </div>
    </>
  )
}
export default FAQPompei
